import React, { useEffect, useState } from "react";
import SEO from "../components/SEO";
import styles from "./Style.module.scss";
import { notFound } from "../utils/images";
import { useNavigate } from "react-router-dom";
import { notFoundMobile } from "../utils/images";

const NotFound = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [isMobile, setMobile] = useState(window.innerWidth < 768);
  const goHome = () => {
    navigate("/");
  };

  const updateMedia = () => {
    setMobile(window.innerWidth < 768);
  };

  return (
    <>
      <SEO
        title="Financial Health Check Not Found Page"
        description="Financial Health Check Not Found Page"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container4}>
        <div className={styles.parentPadding}>
          <div className={styles.imageDiv}>
          {isMobile? (  <img
                src={notFoundMobile}
                alt="Page Not Found"
                width="100%"
                height="100%"
              />): (  <img
                src={notFound}
                alt="Page Not Found"
                width="auto"
                height="auto"
              />)}
            
          </div>

          <div className={styles.notFoundText}>
            The page you are looking for could not be found.
          </div>

          <div className={styles.divflexColumn}>
            <div className={styles.notFoundButton} onClick={goHome}>
              Go to Homepage
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
