import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Style.module.scss";
import SEO from "../components/SEO";
import { fhc } from "../utils/images";
import { logo } from "../utils/images";
import { fhc_mobile } from "../utils/images";

const Home = () => {
  <SEO
    title="Ladda Financial Check"
    description="Ladda Financial Check"
    name="Ladda ng"
    type="article"
  />;

  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setMobile] = useState(window.innerWidth < 768);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const handleForm = () => {
    navigate(
      "/answer-some-questions-and-get-to-know-about-your-personal-finance"
    );
  };

  const goToWebsite = () => {
    window.open("https://www.getladda.com", "_blank");
  }

  const updateMedia = () => {
    setMobile(window.innerWidth < 768);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.parentDiv}>
              <div className={styles.logo} onClick={goToWebsite}>
            <img src={logo} alt="Logo" width="100%" height="100%" />
          </div>
          <div className={styles.div1}>
            <div className={styles.div1left}>
              <h1 className={styles.header}>
                Financial
                <br /> Health Check
              </h1>
              <p className={styles.text}>
                Answer a few questions to help you better understand how you
                manage your money and stay on track to meet your financial
                goals.
              </p>

              <div className={styles.button1} onClick={handleForm}>
                Let's Go
              </div>
            </div>
            <div className={styles.div1right}>
          
              

              {isMobile? (   
                <img
                  src={fhc_mobile}
                  alt="Financial Health Check"
                  className={styles.image}
                  width="100%"
                  height="100%"
                />): (   
                <img
                  src={fhc}
                  alt="Financial Health Check"
                  className={styles.image}
                  width="auto"
                  height="auto"
                />)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
