//home
export const logo =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1677465482/FHC/logo.svg";
export const fhc =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1677465485/FHC/financial-check.svg";
  export const fhc_mobile =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1677465485/FHC/financial-check-mobile.webp";

//not-found
export const notFound =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1677465482/FHC/not-found.svg";

  export const notFoundMobile =
  "https://res.cloudinary.com/dztw4cjew/image/upload/v1677465482/FHC/not-found-mobile.webp";

  //icons
  export const linkedin = "https://res.cloudinary.com/dztw4cjew/image/upload/v1680086225/FHC/linkedin.svg";

  export const facebook = "https://res.cloudinary.com/dztw4cjew/image/upload/v1680086225/FHC/facebook.svg";

  export const twitter = "https://res.cloudinary.com/dztw4cjew/image/upload/v1680086211/FHC/twitter.svg";

  export const whatsapp = "https://res.cloudinary.com/dztw4cjew/image/upload/v1680096893/FHC/whatsapp.svg"