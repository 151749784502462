import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import { Divider } from "@material-ui/core";
import { facebook, linkedin, twitter, whatsapp } from "../utils/images";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./Style.module.scss";
import { FiCopy } from "react-icons/fi";

const SuccessModal = ({ modalOpen, resultData, handleClose }: any) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const baseUrl = window.location.protocol + "//" + window.location.host;
  const currentUrl = window.location.href;

  const shareUrl = baseUrl;
  const score = resultData?.score;
  const categoryName = resultData?.category?.name;
  const categoryDesc = resultData?.category?.description;
  const imageLink = resultData?.category?.img_link;

  const shortUrl = "bit.ly/fhcheck";

  // copy URL to clipboard
  const handleCopy = () => {
    navigator.clipboard
      .writeText(shortUrl)
      .then(() => setCopied(true))
      .catch((err) => console.error(err));
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isDesktop ? "60vw" : "75vw",
    minHeight: "auto",
    maxHeight: "80vh",
    background: "#232323",
    borderRadius: "20px",
    color: "#fff",
    padding: isDesktop ? "25px 0" : "20px",
    overflowY: "auto",
    overflowX: "hidden",
  };

  const bgStyle = {
    backgroundColor: "#f1f1f1",
    borderRadius: "10px",
  };

  const handleModalClose = () => {
    handleClose();
    navigate("/");
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // sx={{
        //   borderRadius: "8px",
        //   border: "2px solid #c1c1c1",
        // }}
      >
        <Box sx={style}>
          <div className={styles.alignCenter}>
            <div className={styles.close} onClick={handleModalClose}>
              <DisabledByDefaultOutlinedIcon fontSize="large" />
            </div>

            <div className={styles.successHeader}>
              FINANCIAL HEALTH CHECK RESULT
            </div>
            <div className={styles.score}>{score}%</div>
            <div className={styles.scoreSubscript}>Your score</div>
            <div className={styles.category}>{categoryName}</div>
            <div className={styles.categoryDesc}>{categoryDesc}</div>
            <div>
              <img
                src={imageLink}
                alt={categoryName}
                className={styles.categoryImage}
              />
            </div>

            <div className={styles.mail}>
              <p>Check your mail for a detailed breakdown of your result</p>
            </div>

            <div className={styles.socialMediaDiv}>
              <div className={styles.socialMediaText}>Share result on</div>
              <div className={styles.socialMedia}>
                <div className={styles.socialMediaIcon}>
                  <TwitterShareButton
                    url={shareUrl}
                    title={`I scored ${score}% in the financial health check survey which puts me under ${categoryName} category`}
                    via="getladda"
                  >
                    <img
                      src={twitter}
                      alt="Twitter"
                      width="100%"
                      height="100%"
                    />
                  </TwitterShareButton>
                </div>
                <div className={styles.socialMediaIcon}>
                  <LinkedinShareButton
                    url={shareUrl}
                    title={`I scored ${score}% in the financial health check survey which puts me under ${categoryName} category`}
                    summary={shareUrl}
                  >
                    <img
                      src={linkedin}
                      alt="LinkedIn"
                      width="100%"
                      height="100%"
                    />
                  </LinkedinShareButton>
                </div>
                <div className={styles.socialMediaIcon}>
                  <FacebookShareButton
                    url={shareUrl}
                    quote={`I scored ${score}% in the financial health check survey which puts me under ${categoryName} category`}
                    hashtag={"#FinancialHealthCheckSurvey"}
                  >
                    <img
                      src={facebook}
                      alt="Facebook"
                      width="100%"
                      height="100%"
                    />
                  </FacebookShareButton>
                </div>

                <div className={styles.socialMediaIcon}>
                  <WhatsappShareButton
                    url={shareUrl}
                    title={`I scored ${score}% in the financial health check survey which puts me under ${categoryName} category`}
                  >
                    <img
                      src={whatsapp}
                      alt="WhatsApp"
                      width="100%"
                      height="100%"
                    />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>

            <div>
              <div className={styles.socialMediaDiv}>
                <div className={styles.socialMediaText}>or use this link</div>
              </div>

              <div className={styles.textfieldDiv}>
                <input
                  className={styles.urlTextfield}
                  type="text"
                  value={shortUrl}
                  readOnly
                />

                <button onClick={handleCopy}>
                  <FiCopy />
                  <div className={styles.copied}>
                    {" "}
                    {copied && <span>Copied!</span>}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SuccessModal;
