export const yesorNo = [
  {
    id: 1,
    value: "true",
    name: "Yes"
  },
  {
    id: 2,
    value: "false",
    name: "No"
  },
];
