import React from "react";
import {
  TextField,
  StepIcon,
  StepIconProps
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { makeStyles } from "@material-ui/core";

export const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "3px 10px",
    border: "1px solid",
    width: "auto",
    height: "41px",
    lineHeight: 1.5,
    backgroundColor: "#FB8B19",
    borderColor: "transparent",
    borderRadius: "5px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#FB8B19",
      boxShadow: "none",
    },
    "&:disabled": {
      backgroundColor: "#F1F1F1",
      color: "#FFF",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#FB8B19",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem #FB8B19",
    },
  });

  
  export const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "#FB8B19",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FB8B19",
    },
    "& .MuiInputBase-input": {
      color: "#FB8B19 !important",
      fontSize: "20px",
      fontFamily: "Lexend",
      marginTop: "30px",
      backgroundColor: "transparent",
    },
    // "& .MuiInputBase-input::after": {
    //   backgroundColor: "#FB8B19",
    // },
    "& .MuiInputBase-input::placeholder": {
      color: "#C9C9C9",
    },
    "&.MuiButtonBase-root": {
      "&.MuiMenuItem-root": {
        // "&.Mui-selected:hover": {
        "& fieldset": {
          fontSize: "16px",
          backgroundColor: "transparent",
        },
      },
    },
    // },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#C9C9C9;",
      },
      "&:hover fieldset": {
        color: "#FB8B19",
      },
      "&.Mui-focused fieldset": {
        color: "#FB8B19",
      },
    },
    "&.MuiFormHelperText-root": {
      "&.Mui-error fieldset": {
        color: "#FB8B19 !important",
      },
    },
  });
  
  export interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }
  
  export const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
      const { onChange, ...other } = props;
  
      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          isAllowed={({ value }: any) => {
            return value >= 0 && value <= 5000000;
          }}
          thousandSeparator
          valueIsNumericString
          prefix="NGN  "
        />
      );
    }
  );
