import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import Home from "./views/Home";
import StepForm from "./views/StepForm";
import NotFound from "./views/NotFound";
import Success from "./views/Success";

const App = () => {
  const helmetContext = {};
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Router>
          <div className="App-container">
            <Routes>
              <Route index element={<Home />} />
              <Route
                path="/answer-some-questions-and-get-to-know-about-your-personal-finance"
                element={<StepForm />}
              />
                <Route path="/success" element={<Success />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </Router>
      </HelmetProvider>
    </>
  );
};

export default App;
