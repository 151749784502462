import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./Style.module.scss";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import { Divider } from "@material-ui/core";
import Facebook from "../assets/facebook.svg";
import Instagram from "../assets/instagram.svg";
import Twitter from "../assets/twitter.svg";
import LinkedIn from "../assets/linkedin.svg";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const baseUrl = window.location.protocol + "//" + window.location.host;
  const currentUrl = window.location.href;

  // const shareUrl = baseUrl + "/answer-some-questions-and-get-to-know-about-your-personal-finance";
  const shareUrl = baseUrl;
  const score = location?.state?.resultData?.score;
  const categoryName = location?.state?.resultData?.category?.name;
  const categoryDesc = location?.state?.resultData?.category?.description;
  const bgStyle = {
    backgroundColor: "#f1f1f1",
    borderRadius: "5px",
  };

  const handleClose = () => {
    navigate("/");
  };
  // useEffect(() => {
  //   if (
  //     sessionStorage.getItem("checkFormValidity") === "false" ||
  //     sessionStorage.getItem("checkFormValidity") === null
  //   ) {
  //     navigate("/");
  //   }
  // });

  return (
    <>
      <div className={styles.container3}>
        <div className={styles.parentDiv3}>
          <div className={styles.close} onClick={handleClose}>
            <DisabledByDefaultOutlinedIcon fontSize="large" />
          </div>

          <div className={styles.successHeader}>
            FINANCIAL HEALTH CHECK RESULT
          </div>
          <div className={styles.score}>{score}%</div>
          <div className={styles.scoreSubscript}>Your score</div>
          <div className={styles.category}>{categoryName}</div>
          <div className={styles.categoryDesc}>{categoryDesc}</div>
          <Divider />

          <div className={styles.mail}>
            Check your mail for a detailed
            <br /> breakdown of your result
          </div>
          <Divider />

          <div className={styles.socialMediaDiv}>
            <div className={styles.socialMediaText}>Share result on</div>

            {/* <FacebookShareButton
              url={"https://www.example.com"}
              quote={"Dummy text!"}
              hashtag="#muo"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>  */}
            <div className={styles.socialMedia}>
              <div className={styles.socialMediaIcon}>
                {" "}
                <TwitterShareButton
                  url={shareUrl}
                  title={`I scored ${score}% in the financial health check survey which puts me under ${categoryName} category`}
                  via="getladda"
                  openShareDialogOnClick={true}
                  // hashtag=["#FinancialHealthCheckSurvey"]
                >
                  <TwitterIcon
                    size={40}
                    bgStyle={bgStyle}
                    className={styles.icons}
                
                  />
                </TwitterShareButton>{" "}
              </div>
              <div className={styles.socialMediaIcon}>
                {" "}
                <LinkedinShareButton
                  url={shareUrl}
                  title={`I scored ${score}% in the financial health check survey which puts me under ${categoryName} category`}
                  summary={shareUrl}
                >
                  <LinkedinIcon
                    size={40}
                    bgStyle={bgStyle}
                    className={styles.icons}
                  />
                </LinkedinShareButton>
              </div>
              <div className={styles.socialMediaIcon}>
                {" "}
                <FacebookShareButton
                  url={shareUrl}
                  quote={`I scored ${score}% in the financial health check survey which puts me under ${categoryName} category`}
                  hashtag={"#FinancialHealthCheckSurvey"}
                >
                  <FacebookIcon
                    size={40}
                    bgStyle={bgStyle}
                    className={styles.icons}
                  />
                </FacebookShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
