export const gender = [
  {
    value: "female",
    name: "Female",
  },
  {
    value: "male",
    name: "Male",
  },
];
